/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import HotWarmColdButton from '../../../../assets/images/logo/HotWarmColdButton.png';
import PaymentWasherLogo from '../../../../assets/images/logo/PaymentWasherLogo.png';
import { currency } from "../../../../dynamicController";

const CleanproWasher = ({ setPage, machine, amount, modeSelection, changeMode, getPaymentAmount, outlet, notificationType,
    email, number, mode, renderDuration, voucherId, discountCode, user, setSendThis, setShowOverlay, setEndpoint }) => {

    const { t } = useTranslation();
    const [showConfirmPayment, setShowConfirmPayment] = useState(false);

    const confirmPayment = () => {
        let sendThis = {
            amount,
            machine,
            paymentAmount: getPaymentAmount("paymentAmount"),
            outlet,
            duration: renderDuration(),
            notificationType,
            email,
            sms_amount: getPaymentAmount("sms"),
            number,
            mode,
            kiosk: true,
        };
        let endPoint = "/api/nonuser/payment";

        if (user) {
            sendThis.voucherId = voucherId;
            sendThis.memberId = user.memberId;
            sendThis.discountCode = discountCode;
            sendThis.point_amount = getPaymentAmount("pointAmount");
            sendThis.point_currency = user.point_currency;
            endPoint = "/api/user/payment";
        }

        setSendThis(sendThis);
        setEndpoint(endPoint);
        setPage('Payment');
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        }}>
            {!showConfirmPayment ? (
                <div style={{
                    width: '1080px',
                    height: '1920px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px'
                }}>
                    <h2 style={{ color: '#000', fontSize: '55px', fontWeight: '800', marginBottom: '60px' }}>
                        {t("Please choose the temperature")}
                    </h2>

                    <div className="position-relative" style={{ width: '300px', height: '300px', marginBottom: '60px' }}>
                        <div style={{ backgroundColor: '#004FC1', width: '100%', height: '100%', borderRadius: '10px', padding: '20px', position: 'relative' }}>
                            <img
                                src={PaymentWasherLogo}
                                alt="Washer"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '64px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none'
                                }}
                            >
                                {machine.name.split('-')[1]}
                            </span>
                        </div>

                        <div
                            style={{
                                backgroundColor: '#004FC1',
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '34px',
                                fontWeight: '600',
                                padding: '10px',
                                borderRadius: '0 0 10px 10px',
                                marginTop: '-10px',
                            }}
                        >
                            {t(machine.type)} {machine.capacity}
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '30px'
                    }}>
                        {
                            modeSelection.map((m, index) => (
                                <div key={index} className="position-relative" style={{ width: '500px', height: '180px', marginBottom: '30px' }}>
                                    <img
                                        src={HotWarmColdButton}
                                        alt={m.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            cursor: 'pointer',
                                            borderRadius: '90px',
                                            border: mode === m.name ? '5px solid #ff0000' : '2px solid #0000ff',
                                        }}
                                        onClick={() => changeMode(m.name)}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '58px',
                                            fontWeight: '600',
                                            color: '#004FC1',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        {t(m.name)}
                                    </span>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            bottom: '10%',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            fontSize: '36px',
                                            fontWeight: '600',
                                            color: '#004FC1',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        {currency(machine.country)} {m.price}
                                    </span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="d-flex justify-content-center position-relative" style={{ gap: '60px', marginTop: '80px' }}>
                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={YesButton}
                                alt="Proceed Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setShowConfirmPayment(true)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Proceed")}
                            </span>
                        </div>

                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={NoButton}
                                alt="Cancel Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setShowOverlay(false)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Cancel")}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{
                    width: '1080px',
                    height: '1920px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '40px'
                }}>
                    <h2 style={{ color: '#000', fontSize: '64px', fontWeight: '800', marginBottom: '60px' }}>
                        {t("Confirm Payment")}
                    </h2>

                    <div className="position-relative" style={{ width: '300px', height: '300px', marginBottom: '60px' }}>
                        <div style={{ backgroundColor: '#004FC1', width: '100%', height: '100%', borderRadius: '10px', padding: '20px', position: 'relative' }}>
                            <img
                                src={PaymentWasherLogo}
                                alt="Washer"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '64px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none'
                                }}
                            >
                                {machine.name.split('-')[1]}
                            </span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#004FC1',
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '34px',
                                fontWeight: '600',
                                padding: '10px',
                                borderRadius: '0 0 10px 10px',
                                marginTop: '-10px',
                            }}
                        >
                            {t(machine.type)} {machine.capacity}
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '30px'
                    }}>
                        <div className="position-relative" style={{ width: '500px', height: '180px', marginBottom: '30px' }}>
                            <img
                                src={HotWarmColdButton}
                                alt={mode}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                    borderRadius: '90px',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '25%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '55px',
                                    fontWeight: '600',
                                    color: '#004FC1',
                                    pointerEvents: 'none',
                                }}
                            >
                                {t(mode)}
                            </span>

                            <span
                                style={{
                                    position: 'absolute',
                                    top: '35%',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    fontSize: '36px',
                                    fontWeight: '600',
                                    color: '#004FC1',
                                    pointerEvents: 'none',
                                }}
                            >
                                {renderDuration()} {t("minutes")}
                            </span>

                            <span
                                style={{
                                    position: 'absolute',
                                    bottom: '0%',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    fontSize: '55px',
                                    fontWeight: '600',
                                    color: '#004FC1',
                                    pointerEvents: 'none',
                                }}
                            >
                                {currency(machine.country)} {amount}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center position-relative" style={{ gap: '60px', marginTop: '80px' }}>
                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={YesButton}
                                alt="Pay Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={confirmPayment}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Pay")}
                            </span>
                        </div>

                        <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                            <img
                                src={NoButton}
                                alt="Cancel Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setShowConfirmPayment(false)}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t("Cancel")}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CleanproWasher;
