import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import navIcon from "../../../../../assets/images/icons/icn-hamburger.png";

import cpPlusLogo from "../../../../../assets/images/logo/Logo_Cleanproplus-white.png";
import profile from "../../../../../assets/images/icons/icn-profile.png";
import creditBearBlue from "../../../../../assets/images/icons/icn-credit.png";
import creditBear from "../../../../../assets/images/icons/icn-creditGrey.png";
import voucherIcon from "../../../../../assets/images/icons/icn-voucher.png";

const CleanproHeader = ({ user, setToggled, changeNav }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div id="user-header">
            <div className="d-flex justify-content-between">
                <img
                    style={{ width: "200px", objectFit: "contain" }}
                    src={cpPlusLogo}
                    alt=""
                />
                <img
                    style={{ width: "28px", objectFit: "contain" }}
                    onClick={(e) => setToggled(true)}
                    src={navIcon}
                    alt=""
                />
            </div>
            <div className="mt-3 d-flex align-items-center">
                <div>
                    {user && user.profilePicture ? (
                        <img
                            src={user ? user.profilePicture : ""}
                            alt=""
                            className="img-fluid"
                            style={{ width: "46px", height: "46px", borderRadius: "50%" }}
                        />
                    ) : (
                        <img
                            style={{ width: "46px", objectFit: "contain" }}
                            src={profile}
                            alt=""
                        />
                    )}
                </div>
                <div className="mx-2">
                    <p
                        style={{ fontSize: "18px" }}
                        className="font-weight-bold"
                    >
                        {t("Hello")}, {user ? user.name : null}
                    </p>
                    <p className="small-t">{user ? user.memberId : null}</p>
                </div>
            </div>

            {/* <div id="user-info-container">
              <img
                style={{ width: "48px", objectFit: "contain" }}
                src={creditBearBlue}
                alt=""
              />
              <div style={{ width: "40%", marginLeft: "10px" }}>
                <p>{t("Wallet Balance")}</p>
                <span
                  style={{ fontSize: "24px" }}
                  className="t-blk font-weight-bold"
                >
                  {user.token}
                </span>{" "}
                <span>{t("credits")}</span>
              </div>
              <Link
                to="/user/reload"
                style={{ textDecoration: "none" }}
                className="col"
              >
                <div id="reload" className="reload-button">
                  {t("Topup")}
                  <i className="bi bi-plus"></i>
                </div>
              </Link>
            </div> */}

            <div id="user-info-container">
                <div className="d-flex align-center" style={{ flexDirection: "column", borderRight: "1px solid #E4E4E4", width: "40%" }}>
                    <div className="d-flex justify-content-center">
                        <img src={creditBear} alt="" style={{ width: "35px" }} />
                        <p
                            style={{ fontSize: "24px" }}
                            className="t-blk font-weight-bold ps-2"
                        >
                            {user.token}
                        </p>
                    </div>
                    <p className="pt-1" onClick={() => history.push("/user/reload")}>{t("Credit balance")} &gt;</p>
                </div>

                <div className="d-flex align-center" style={{ flexDirection: "column", borderRight: "1px solid #E4E4E4", width: "33%" }}>
                    <div className="d-flex justify-content-center">
                        <img src={creditBearBlue} alt="" style={{ width: "35px" }} />
                        <p
                            style={{ fontSize: "24px" }}
                            className="t-blk font-weight-bold ps-2"
                        >
                            {user.point}
                        </p>
                    </div>
                    <p className="pt-1" onClick={() => changeNav("point")}>{user.loyalty_point_name} &gt;</p>
                </div>

                <div className="d-flex align-center" style={{ flexDirection: "column", width: "27%" }}>
                    <div className="d-flex justify-content-center">
                        <img src={voucherIcon} alt="" style={{ width: "35px", objectFit: "contain" }} />
                        <p
                            style={{ fontSize: "24px" }}
                            className="t-blk font-weight-bold ps-2"
                        >
                            {user ? user.vouchers.filter(voucher => voucher.available).length : "0"}
                        </p>
                    </div>
                    <p className="pt-1" onClick={() => changeNav("coupon")}>{t("Vouchers")} &gt;</p>
                </div>
            </div>
        </div>
    )
}

export default CleanproHeader;